import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router';

function Main(props: PropsWithChildren) {
	const { children } = props;
	return (
		<div className="d-flex flex-column vh-100">
			<div className="bg-black h-100 overflow-auto d-flex">
				{children}
				<Outlet />
			</div>
		</div>
	);
}

export default Main;
