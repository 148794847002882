import { RefObject, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Link, useLocation } from 'react-router-dom';

const sidebarTabs = [
	{
		path: '/',
		title: 'Home',
		icon: 'house',
	},
	{
		path: '/submissions',
		title: 'Submissions',
		icon: 'postcard',
	},
	{
		path: '/products-list',
		title: 'Products',
		icon: 'x-diamond',
	},
];

function SidebarTabs() {
	const location = useLocation();
	return (
		<>
			{sidebarTabs.map((tab, index) => {
				return (
					<Link
						to={tab.path}
						key={index}
						className={`mt-2 p-2 px-3
							${location.pathname === tab.path ? 'bg-black text-white rounded-start-5 me-0 mx-3' : ''}
						`}
					>
						<i
							className={`bi bi-${tab.icon}${location.pathname === tab.path ? '-fill' : ''} fs-4`}
						></i>
						<span className="mx-2">{tab.title}</span>
					</Link>
				);
			})}
		</>
	);
}

function Sidebar() {
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const sidebarRef: RefObject<HTMLDivElement> = useRef(null);

	function handleToggleNav() {
		setIsSidebarOpen(prev => !prev);
	}

	return (
		<>
			<div
				ref={sidebarRef}
				className={`${styles.sidebar} ${isSidebarOpen ? styles.open : styles.closed} bg-white`}
			>
				<div className={styles.sidebarHeader}>
					<h3>Menu</h3>
					<button
						className={styles.toggleCta}
						onClick={handleToggleNav}
					>
						<i
							className={`bi bi-${isSidebarOpen ? 'arrow-bar-left' : 'list'} fs-4`}
						></i>
					</button>
				</div>
				<SidebarTabs />
			</div>
		</>
	);
}

export default Sidebar;
