import { useEffect, useState } from 'react';
import Header from '../../lib/components/Header';
import { getAllProducts, IProductsInfo } from '../../lib/utils/apis';
import styles from './styles.module.scss';
import { useAppStore } from '../../lib/stores/appMain';

function ProductList() {
	const { APP } = useAppStore(state => state);
	const [products, setProducts] = useState<IProductsInfo[]>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const tableHeader = ['#', 'Image', 'Name', 'Description', 'Actions'];

	async function fetchAllProducts() {
		try {
			setLoading(true);
			const data = await getAllProducts();
			const products = data?.dynamics || [];
			setProducts(products);
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setLoading(false);
		}
	}
	//app.juscollab.com/?agency=fakestyletales&product=671fe3cef62a822ad509fa86
	function handleCopyLink(productId: string) {
		const url = `https://app.juscollab.com?agency=${APP.User?.AgencyKey[0]}&product=${productId}`;
		navigator.clipboard.writeText(url);
	}

	useEffect(() => {
		fetchAllProducts();
	}, []);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center h-100 w-100">
				<p>Loading...</p>
			</div>
		);
	}

	if (error) {
		return (
			<div className="d-flex align-items-center justify-content-center h-100 w-100">
				<p>Some error occurred. Please try again after sometime.</p>
			</div>
		);
	}

	return (
		<div className="p-3 w-100 overflow-auto">
			<Header text="Products" classes="my-3" />
			<div className="rounded overflow-hidden">
				<table className="table rounded">
					<thead className="table-light align-middle">
						<tr>
							{tableHeader.map((item, index) => (
								<th key={index}>{item}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{products &&
							products.map((item, index) => {
								return (
									<tr>
										<th>{index + 1}.</th>
										<td className={styles.td}>
											<img
												className="w-100 rounded"
												src={item.image}
												height={50}
											/>
										</td>
										<td className={styles.td}>
											{item.name}
										</td>
										<td className={styles.td}>
											{item.caption}
										</td>
										<td className={styles.td}>
											<button
												title="Copy link"
												className="btn outline-0 border-0 p-0 m-0"
												onClick={() =>
													handleCopyLink(item._id)
												}
											>
												🔗
											</button>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default ProductList;
