import { useNavigate } from 'react-router';
import styles from './styles.module.scss';
import { useAppStore } from '../../lib/stores/appMain';
import { useCallback, useMemo } from 'react';

function Login() {
	const navigate = useNavigate();
	const { APP } = useAppStore(state => state);

	function handleSubmit() {
		navigate('/oauth/auth');
	}

	const handleLoggedinUserSubmit = useCallback(() => {
		navigate(`/`);
	}, [navigate]);

	const renderLoggedInCta = useMemo(() => {
		if (!APP.Auth) {
			return null;
		}

		return (
			<>
				<p className="text-center">OR</p>
				<button
					onClick={handleLoggedinUserSubmit}
					type="submit"
					className="w-100 border rounded-pill d-flex align-items-center btn d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded"
				>
					<p className="m-0">
						Continue With{' '}
						<span className="fw-bold">{APP.User?.Name}</span>
					</p>
				</button>
			</>
		);
	}, [APP.Auth, APP.User?.Name, handleLoggedinUserSubmit]);

	return (
		<div className="d-flex align-items-center justify-content-center h-100 flex-column gap-5 w-100">
			<p className="fs-3 fw-bold text-center">
				Welcome to <br /> Admin Dashboard
			</p>
			<div className="d-flex align-item-center justify-content-ceter gap-3 flex-column w-25">
				<button
					onClick={handleSubmit}
					type="submit"
					className={`${styles.submitCta} rounded-pill d-flex align-items-center btn bg-primary d-flex items-center justify-content-center gap-2 p-2 px-3 text-white rounded`}
				>
					<p className="m-0">
						<i className="bi bi-instagram me-1"></i> Login with
						Instagram
					</p>
				</button>
				{renderLoggedInCta}
			</div>
		</div>
	);
}

export default Login;
