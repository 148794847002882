import { useAppStore } from '../../lib/stores/appMain';

function Home() {
	const { APP } = useAppStore(state => state);
	return (
		<div className="d-flex align-items-center justify-content-center h-100 w-100 m-3 flex-column gap-3">
			<img src={APP.User?.Image} height={100} className="rounded-pill" />
			<p className="fs-2 text-center">
				Welcome <br />
				<span className="fw-bold text-primary">{APP.User?.Name}</span>
			</p>
		</div>
	);
}

export default Home;
