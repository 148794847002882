import { PropsWithChildren, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppStore } from '../../../stores/appMain';

function Secure(props: PropsWithChildren) {
	const { children } = props;
	const { APP } = useAppStore(state => state);
	const [isLoggedIn, setIsLoggedIn] = useState(!!APP.User?.ID);
	const location = useLocation();

	useEffect(() => {
		setIsLoggedIn(!!APP.Auth?.AccessToken);
	}, [location, APP.Auth]);

	if (!isLoggedIn) {
		return (
			<div className="d-flex align-items-center justify-content-center bg-black vh-100">
				<p>
					Unauthorized user. Please <Link to="login">Login</Link>
				</p>
			</div>
		);
	}

	return <>{children}</>;
}

export default Secure;
